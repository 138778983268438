import Swiper, { Pagination } from 'swiper';

class AwardsSlider {
    constructor ($element) {
        this.$awardsSliderRoot = $element;
        this.$slides = this.$awardsSliderRoot.querySelectorAll('[data-awards-slider="slide"]');
        this.slider = [];
    }

    initialize () {
        if (this.$slides.length > 1) {
            this.initSlider();
        }
    }

    initSlider () {
        Swiper.use([Pagination]);
        this.slider = new Swiper(this.$awardsSliderRoot, {
            slidesPerView: 'auto',
            spaceBetween: 40,
            centeredSlides: false,
            initialSlide: 0,
            speed: 1000,
            slideClass: 'sitefooter__award-item',
            wrapperClass: 'sitefooter__award-items',
            watchOverflow: true,
            pagination: {
                el: '.sitefooter__awards-pagination',
                type: 'progressbar'
            }
        });
    }
}

export { AwardsSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    console.log('content loaded');
    if ($context) {
        const $awardsSliders = $context.querySelectorAll('[data-awards-slider="root"]');
        if ($awardsSliders.length > 0) {
            $awardsSliders.forEach((awardSlider) => {
                const $awardSlider = new AwardsSlider(awardSlider);
                $awardSlider.initialize();
            });
        }
    }
});
